import React, { useRef, useEffect, useState } from 'react';
import { useOnScreen } from 'hooks/useOnScreen';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import {
  StepBase,
  CodeBlock,
  AccordionWithUnderline,
  Button,
  TextField,
} from 'subframe/index';
import { Text } from '@subframe/core';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import styles from '../AddClusterTerraformTab.module.scss';
import { RouterLink } from 'components/RouterLink';
import { Tracker } from 'components/tracker';
import { TabsWithContent } from 'components/design-system/Tabs';
import { CopyToClipboard } from 'src/components/design-system/CopyToClipboard';
import { SystemRequirements } from './SystemRequirements';
import { IngestionToken } from 'api/models';
import TokenSelector from 'components/TokenSelector';
import { CHKK_TERRAFORM_K8S_CONNECTOR_VERSION } from 'constants/chkk-version';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import { toastAutoHideDuration } from 'constants/toasts';
import { fieldIsRequiredErrorMessage } from 'constants/input-validation';
import { Badge } from 'subframe/index';

export type AddNewTerraformClusterProps = {
  tokens: IngestionToken[];
};

export const AddNewTerraformCluster = ({
  tokens,
}: AddNewTerraformClusterProps) => {
  const ref: React.MutableRefObject<HTMLElement | undefined> = useRef();
  const isVisible = useOnScreen(ref as React.MutableRefObject<HTMLElement>);

  const [installMethod, setInstallMethod] = useState('');
  const [selectedToken, setSelectedToken] = useState<string | null>();
  const [proxyConfigString, setProxyConfigString] = useState<string | null>();
  const { enqueueSnackbar } = useSnackbar();

  const { logEvent } = AnalyticsEventLogger();

  const clusterPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );

  const troubleshootPage = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/troubleshoot-k8s-connector?problem=configure-cluster-name-and-environment-terraform',
  );

  useEffect(() => {
    if (isVisible) {
      logEvent('add-cluster-scroll-to-bottom', {
        type: 'new-helm-cluster',
      });
    }
  }, [isVisible, logEvent]);

  interface SchemaFields {
    HTTP_PROXY: string;
    HTTPS_PROXY: string;
    NO_PROXY: string;
  }

  const proxyConfigSchema = Yup.object().shape({
    HTTP_PROXY: Yup.string().required(
      fieldIsRequiredErrorMessage('HTTP Proxy'),
    ),
    HTTPS_PROXY: Yup.string().required(
      fieldIsRequiredErrorMessage('HTTPS Proxy'),
    ),
    NO_PROXY: Yup.string().optional(),
  });

  const methods = useForm<SchemaFields>({
    resolver: yupResolver(proxyConfigSchema),
  });

  const {
    handleSubmit: handleFormSubmit,
    formState: { errors, isValid },
  } = methods;

  const saveProxyConfig = async (data: SchemaFields) => {
    const httpProxy = data.HTTP_PROXY;
    const httpsProxy = data.HTTPS_PROXY;
    const noProxy = data.NO_PROXY;

    const proxyString = `
    proxy = {
      http_proxy  = "${httpProxy}"
      https_proxy = "${httpsProxy}"
      no_proxy    = "${noProxy}"
    }
    disableAnalytics = true`;

    setProxyConfigString(proxyString);
    enqueueSnackbar('HTTP(S) proxy configuration saved successfully', {
      variant: 'success',
      autoHideDuration: toastAutoHideDuration,
    });
  };

  const resetForm = () => {
    methods.reset();
    setProxyConfigString(null);
    enqueueSnackbar('HTTP(S) proxy configuration reverted successfully', {
      variant: 'success',
      autoHideDuration: toastAutoHideDuration,
    });
  };

  const [firstOpen, setFirstOpen] = useState(true);
  const [secondOpen, setSecondOpen] = useState(false);
  const [thirdOpen, setThirdOpen] = useState(false);
  return (
    <div style={{ display: 'contents' }}>
      <StepBase
        stepTitle="System Requirements"
        lastStep={false}
        stepNumber="1"
        variant="default"
        open={firstOpen}
        onOpenChange={(open) => {
          setFirstOpen(!firstOpen);
          if (open) {
            logEvent('add-cluster-terraform-step1', {
              step: 'provider-requirements',
            });
          }
        }}
        actionButtons={null}
      >
        <StepBase.Container className={styles['container-eff57117']}>
          <span className={styles['bodyBoldText']} />
          <SystemRequirements showKubectl={false} />
          <AccordionWithUnderline headerText="Provider Requirements">
            <StepBase.BulletedLine>
              hashicorp/helm: &gt;= version 2
            </StepBase.BulletedLine>
            <StepBase.BulletedLine>
              gavinbunney/kubectl: &gt;= v1.19
            </StepBase.BulletedLine>
          </AccordionWithUnderline>
        </StepBase.Container>
      </StepBase>
      <StepBase
        data-cy="terraform-step2"
        stepTitle="Allowlist chkk.io in firewall rules"
        stepBody=""
        stepNumber="2"
        open={secondOpen}
        onOpenChange={(open) => {
          setSecondOpen(!secondOpen);
          if (open) {
            logEvent('add-cluster-terraform-step2', {
              step: 'allowlist-chkk.io-in-firewall',
            });
          }
        }}
        actionButtons={null}
        badges={<Badge variant="neutral">Optional</Badge>}
      >
        <StepBase.Container className={styles['container']}>
          <Text>
            Ensure that the following domains are allowlisted in your firewall
            rules:
          </Text>
          <StepBase.BulletedLine>
            chkk.io and its subdomains
          </StepBase.BulletedLine>
          <StepBase.BulletedLine>
            s3.amazonaws.com and its subdomains
          </StepBase.BulletedLine>
        </StepBase.Container>
      </StepBase>
      <StepBase
        stepTitle="Enter HTTP(S) proxy configuration"
        stepBody=""
        stepNumber="3"
        open={thirdOpen}
        onOpenChange={(open) => {
          setThirdOpen(!thirdOpen);
          if (open) {
            logEvent('add-cluster-terraform-step3', {
              step: 'Configure-Proxy',
            });
          }
        }}
        actionButtons={null}
        badges={<Badge variant="neutral">Optional</Badge>}
      >
        <StepBase.Container className={styles['container']}>
          <Text>
            Provide the following configurations from your proxy server:
          </Text>
          <FormProvider
            onSubmit={handleFormSubmit(saveProxyConfig)}
            methods={methods}
            style={{ width: '100%' }}
          >
            <div className="flex w-full flex-col items-start gap-4 px-1 py-1">
              <div className="flex w-full items-center justify-between">
                <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
                  HTTP_PROXY
                </span>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('HTTP_PROXY').invalid}
                  label=""
                  helpText={errors?.HTTP_PROXY?.message}
                >
                  <TextField.Input {...methods.register('HTTP_PROXY')} />
                </TextField>
              </div>
              <div className="flex w-full items-center justify-between">
                <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
                  HTTPS_PROXY
                </span>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('HTTPS_PROXY').invalid}
                  label=""
                  helpText={errors?.HTTPS_PROXY?.message}
                >
                  <TextField.Input {...methods.register('HTTPS_PROXY')} />
                </TextField>
              </div>
              <div className="flex w-full items-center justify-between">
                <span className="w-28 flex-none text-body-bold font-body-bold text-default-font">
                  NO_PROXY
                </span>
                <TextField
                  className="h-auto grow shrink-0 basis-0"
                  error={methods.getFieldState('NO_PROXY').invalid}
                  label=""
                  helpText={errors?.NO_PROXY?.message}
                >
                  <TextField.Input {...methods.register('NO_PROXY')} />
                </TextField>
              </div>
              <div className="flex w-full flex-col items-end gap-2 px-1 py-1">
                <div className="flex items-start gap-2">
                  {methods.formState.isDirty && (
                    <Button
                      variant="brand-secondary"
                      icon="FeatherUndo"
                      onClick={() => resetForm()}
                    >
                      Revert
                    </Button>
                  )}
                  <Button icon="FeatherCornerDownRight" type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </FormProvider>
        </StepBase.Container>
      </StepBase>

      <StepBase
        stepTitle="Deploy K8s Connector"
        lastStep={false}
        variant="default"
        stepNumber="4"
        actionButtons={null}
        onOpenChange={(open) => {
          if (open) {
            logEvent('add-cluster-terraform-step2', {
              step: 'Deploy-K8s-Connector',
            });
          }
        }}
      >
        <StepBase.Container className={styles['container']}>
          <div className={styles['stack-3cdada58']}>
            <div className="flex items-center gap-2">
              <Text variant="body">
                Before proceeding, select the Access Token from the dropdown
                provided:
              </Text>
              <TokenSelector
                label=""
                placeholder="Select Token"
                tokens={tokens}
                value={selectedToken!}
                onValueChange={(value: string) => {
                  setSelectedToken(value);
                }}
              />
            </div>
            <TabsWithContent
              initialTabId={installMethod || undefined}
              onTabChange={(newTabId) => {
                setInstallMethod(newTabId);
              }}
              tabs={[
                {
                  id: 'default',
                  title: 'Create new Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <span className={styles['bodyText']}>
                        Use the following snippet in your IaC to deploy the Chkk
                        K8s Connector with a new secret:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        className="sensitive select-none cursor-pointer"
                        text={`module "chkk_k8s_connector" {
  source     = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"

  create_namespace = true
  namespace        = "chkk-system"

  chkk_operator_config = {
    secret = {
      chkkAccessToken : "${selectedToken || '<ACCESS-TOKEN>'}"
    }${proxyConfigString || ''}
  }
}`}
                        singleLine={false}
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-secret',
                  title: 'Use existing Secret with new Service Account',
                  content: (
                    <div className={styles['stack-d3d77bdc']}>
                      <span className={styles['bodyText']}>
                        Create namespace:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        text={`resource "kubernetes_namespace" "chkk_system" {
  metadata {
    name = "chkk-system"
  }
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        To use a separately configured secret object containing
                        your Chkk access token, first create the Secret:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        className="sensitive select-none cursor-pointer"
                        text={`resource "kubernetes_secret" "chkk_operator_token" {
  metadata {
    name      = "chkk-operator-token"
    namespace = "chkk-system"
  }

  data = {
    CHKK_ACCESS_TOKEN = "${selectedToken || '<ACCESS-TOKEN>'}"
  }

  type = "Opaque"
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        In addition to the Secret for Chkk Operator, create a
                        Secret for Chkk Agent:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        className="sensitive select-none cursor-pointer"
                        text={`resource "kubernetes_secret" "chkk_agent_token" {
  metadata {
    name      = "chkk-agent-token"
    namespace = "chkk-system"
  }

  data = {
    CHKK_ACCESS_TOKEN = "${selectedToken || '<ACCESS-TOKEN>'}"
  }

  type = "Opaque"
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        With the secret created, install the Chkk Operator:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        text={`module "chkk_k8s_connector" {
  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"

  create_namespace = false
  namespace        = "chkk-system"

  chkk_operator_config = {
    secret = {
      create = false
      ref = {
        secretName = "chkk-operator-token",
        keyName    = "CHKK_ACCESS_TOKEN"
      }
    }${proxyConfigString || ''}
  }

  chkk_agent_config = {
    secret = {
      secretName = "chkk-agent-token",
      keyName    = "CHKK_ACCESS_TOKEN"
    }
  }
}`}
                        singleLine={false}
                      />
                    </div>
                  ),
                },
                {
                  id: 'existing-service-account',
                  title: 'Use existing Secret and Service Account',
                  content: (
                    <div className={styles['stack-f1126c39']}>
                      <span className={styles['bodyText']}>
                        Create namespace:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        text={`resource "kubernetes_namespace" "chkk_system" {
  metadata {
    name = "chkk-system"
  }
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        To create the Service Account and Secret outside of the
                        Helm Chart, you first have to create the service account
                        for Chkk Operator with the associated permissions:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        text={`resource "kubernetes_service_account" "chkk_operator" {
  metadata {
    name      = "chkk-operator"
    namespace = "chkk-system"
  }
}

resource "kubernetes_cluster_role" "chkk_operator_read_all_role" {
  metadata {
    name = "chkk-operator-read-all-role"
  }

  rule {
    api_groups = ["*"]
    resources  = ["*"]
    verbs      = ["get", "list", "watch"]
  }
}

resource "kubernetes_cluster_role_binding" "chkk_operator_rolebinding" {
  metadata {
    name = "chkk-operator-rolebinding"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "ClusterRole"
    name      = kubernetes_cluster_role.chkk_operator_read_all_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_operator.metadata[0].name
    namespace = kubernetes_service_account.chkk_operator.metadata[0].namespace
  }
}

resource "kubernetes_cluster_role" "chkk_operator_proxy_access_role" {
  metadata {
    name = "chkk-operator-proxy-access-role"
  }

  rule {
    api_groups = ["authentication.k8s.io"]
    resources  = ["tokenreviews"]
    verbs      = ["create"]
  }

  rule {
    api_groups = ["authorization.k8s.io"]
    resources  = ["subjectaccessreviews"]
    verbs      = ["create"]
  }

  rule {
    non_resource_urls = ["/metrics"]
    verbs             = ["get"]
  }
}

resource "kubernetes_cluster_role_binding" "chkk_operator_proxy_rolebinding" {
  metadata {
    name = "chkk-operator-proxy-rolebinding"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "ClusterRole"
    name      = kubernetes_cluster_role.chkk_operator_proxy_access_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_operator.metadata[0].name
    namespace = kubernetes_service_account.chkk_operator.metadata[0].namespace
  }
}

resource "kubernetes_role" "chkk_operator_leader_election_role" {
  metadata {
    name      = "chkk-operator-leader-election-role"
    namespace = "chkk-system"
  }

  rule {
    api_groups = [""]
    resources  = ["configmaps"]
    verbs      = ["get", "list", "watch", "create", "update", "patch", "delete"]
  }

  rule {
    api_groups = ["coordination.k8s.io"]
    resources  = ["leases"]
    verbs      = ["get", "list", "watch", "create", "update", "patch", "delete"]
  }

  rule {
    api_groups = [""]
    resources  = ["events"]
    verbs      = ["create", "patch"]
  }
}

resource "kubernetes_role_binding" "chkk_operator_leader_election_rolebinding" {
  metadata {
    name      = "chkk-operator-leader-election-rolebinding"
    namespace = "chkk-system"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "Role"
    name      = kubernetes_role.chkk_operator_leader_election_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_operator.metadata[0].name
    namespace = kubernetes_service_account.chkk_operator.metadata[0].namespace
  }
}

resource "kubernetes_role" "chkk_operator_namespace_role" {
  metadata {
    name      = "chkk-operator-namespace-role"
    namespace = "chkk-system"
  }

  rule {
    api_groups = [""]
    resources  = ["configmaps", "pods", "events"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch"]
  }

  rule {
    api_groups = ["apps"]
    resources  = ["deployments"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch"]
  }

  rule {
    api_groups = ["batch"]
    resources  = ["cronjobs", "jobs"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch"]
  }

  rule {
    api_groups = ["k8s.chkk.io"]
    resources  = ["chkkagents", "chkkagents/finalizers", "chkkagents/status"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch", "update"]
  }
}

resource "kubernetes_role_binding" "chkk_operator_namespace_rolebinding" {
  metadata {
    name      = "chkk-operator-namespace-rolebinding"
    namespace = "chkk-system"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "Role"
    name      = kubernetes_role.chkk_operator_namespace_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_operator.metadata[0].name
    namespace = kubernetes_service_account.chkk_operator.metadata[0].namespace
  }
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        In addition to the Service Account for Chkk Operator,
                        create a Service Account for Chkk Agent:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        text={`resource "kubernetes_service_account" "chkk_agent" {
  metadata {
    name      = "chkk-agent"
    namespace = "chkk-system"
  }
}

resource "kubernetes_cluster_role" "chkk_agent_read_all_role" {
  metadata {
    name = "chkk-agent-read-all-role"
  }

  rule {
    api_groups = ["*"]
    resources  = ["*"]
    verbs      = ["get", "list", "watch"]
  }
}

resource "kubernetes_cluster_role_binding" "chkk_agent_rolebinding" {
  metadata {
    name = "chkk-agent-rolebinding"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "ClusterRole"
    name      = kubernetes_cluster_role.chkk_agent_read_all_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_agent.metadata[0].name
    namespace = kubernetes_service_account.chkk_agent.metadata[0].namespace
  }
}

resource "kubernetes_role" "chkk_agent_namespace_role" {
  metadata {
    name      = "chkk-agent-namespace-role"
    namespace = "chkk-system"
  }

  rule {
    api_groups = ["batch"]
    resources  = ["cronjobs", "jobs"]
    verbs      = ["create", "delete", "get", "list", "patch", "update", "watch"]
  }
}

resource "kubernetes_role_binding" "chkk_agent_namespace_rolebinding" {
  metadata {
    name      = "chkk-agent-namespace-rolebinding"
    namespace = "chkk-system"
  }

  role_ref {
    api_group = "rbac.authorization.k8s.io"
    kind      = "Role"
    name      = kubernetes_role.chkk_agent_namespace_role.metadata[0].name
  }

  subject {
    kind      = "ServiceAccount"
    name      = kubernetes_service_account.chkk_agent.metadata[0].name
    namespace = kubernetes_service_account.chkk_agent.metadata[0].namespace
  }
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        With the Service Account setup, create secret for Chkk
                        Operator:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        className="sensitive select-none cursor-pointer"
                        text={`resource "kubernetes_secret" "chkk_operator_token" {
  metadata {
    name      = "chkk-operator-token"
    namespace = "chkk-system"
  }

  data = {
    CHKK_ACCESS_TOKEN = "${selectedToken || '<ACCESS-TOKEN>'}"
  }

  type = "Opaque"
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        In addition to the Secret for Chkk Operator, create a
                        Secret for Chkk Agent:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        className="sensitive select-none cursor-pointer"
                        text={`resource "kubernetes_secret" "chkk_agent_token" {
  metadata {
    name      = "chkk-agent-token"
    namespace = "chkk-system"
  }

  data = {
    CHKK_ACCESS_TOKEN = "${selectedToken || '<ACCESS-TOKEN>'}"
  }

  type = "Opaque"
}`}
                        singleLine={false}
                      />
                      <span className={styles['bodyText']}>
                        With the Service Account and Secret setup, you can
                        deploy the Chkk Operator using:
                      </span>
                      <CopyToClipboard
                        language="hcl"
                        text={`module "chkk_k8s_connector" {
  source = "git::https://github.com/chkk-io/terraform-chkk-k8s-connector.git?ref=${CHKK_TERRAFORM_K8S_CONNECTOR_VERSION}"

  create_namespace = false
  namespace        = "chkk-system"

  chkk_operator_config = {
    secret = {
      create = false
      ref = {
        secretName = "chkk-operator-token",
        keyName    = "CHKK_ACCESS_TOKEN"
      }
    }
    serviceAccount = {
      create = false
      name   = "chkk-operator"
    }${proxyConfigString || ''}
  }

  chkk_agent_config = {
    secret = {
      secretName = "chkk-agent-token",
      keyName    = "CHKK_ACCESS_TOKEN"
    }
    serviceAccount = {
      create = false
      name   = "chkk-agent"
    }
  }
}
`}
                        singleLine={false}
                      />
                    </div>
                  ),
                },
              ]}
            />

            <Text className={styles['bodyText']}>
              For more details on the Chkk K8s Connector terraform module see
              the{' '}
              <Tracker event="terraform_documentation">
                <a
                  href={
                    'https://github.com/chkk-io/terraform-chkk-k8s-connector/blob/main/README.md'
                  }
                  className="hoverable-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  documentation
                </a>
              </Tracker>
              .
            </Text>
            <Text className="text-body font-body text-default-font">
              For guidance on configuring your cluster name and environment
              during the onboarding process, refer to the{' '}
              <Tracker event="chkkagent">
                <RouterLink to={troubleshootPage} className="hoverable-link">
                  Troubleshooting doc
                </RouterLink>
              </Tracker>
              .
            </Text>
          </div>
        </StepBase.Container>
      </StepBase>

      <StepBase
        stepTitle="Verify the K8s Connector is active"
        stepNumber="5"
        onOpenChange={(open) => {
          if (open) {
            logEvent('add-cluster-terraform-step5', {
              step: 'Verify-K8s-Connector',
            });
          }
        }}
        lastStep={true}
        actionButtons={
          <RouterLink to={clusterPath}>
            <Button
              variant="brand-secondary"
              size="medium"
              data-cy="go-to-cluster-button"
              onClick={() =>
                logEvent('add-new-cluster-go-to-cluster-button-click')
              }
            >
              Go to Clusters
            </Button>{' '}
          </RouterLink>
        }
      >
        <StepBase.Container className={styles['container-479bae3c']}>
          <div className={styles['stack-9cb4dbbb']}>
            <span className={styles['bodyText']}>
              You can verify the K8s Connector has been installed in your
              cluster by running the following command:
            </span>
            <CopyToClipboard
              language="shell"
              text="kubectl get chkkagent -n chkk-system"
            />
            <AccordionWithUnderline headerText="Sample Output:">
              <CodeBlock className="h-auto w-full flex-none">
                {`NAME           AGE
chkk-agent     65s
`}
              </CodeBlock>
            </AccordionWithUnderline>
            <div className={styles['stack-a29037e2']}>
              <span className={styles['bodyText']}>
                Your cluster should appear under{' '}
                <RouterLink
                  to={clusterPath}
                  className="hoverable-link"
                  onClick={() =>
                    logEvent('add-cluster-terraform-clusters-view-click')
                  }
                >
                  Clusters view
                </RouterLink>{' '}
                shortly (in &lt; 1 min) and Chkk will kick off a scan to detect
                Operational Risks that Need Attention in your cluster.
              </span>
              <span className={styles['bodyText']}>
                We&#39;ll also notify you via email when the first scan
                completes.
              </span>
              <span className={styles['text-3e648570']}>
                If you face any issues, feel free to reach out to us on{' '}
                <a
                  href={'http://slack.chkk.io'}
                  className="hoverable-link"
                  target="_blank"
                  onClick={() => logEvent('add-cluster-terraform-slack-click')}
                  rel="noreferrer"
                >
                  Slack
                </a>{' '}
                or email us at{' '}
                <a
                  href="mailto:support@chkk.io"
                  className="hoverable-link"
                  onClick={() =>
                    logEvent('add-cluster-terraform-email-support-click')
                  }
                  rel="noreferrer"
                >
                  support@chkk.io
                </a>
                .
              </span>
            </div>
          </div>
        </StepBase.Container>
      </StepBase>
      <div ref={ref}></div>
    </div>
  );
};
