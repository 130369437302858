'use client';
import { createUpgradeTemplate, useListSubscriptions } from 'api/frontend';
import {
  AddonInstance,
  Cluster,
  DeploymentStrategy,
  ResolvedEntitlementFeaturesItem,
  UpgradeTemplate,
} from 'api/models';
import { ConsoleLoader } from 'components/Loader';
import { LockingWrapper } from 'components/LockingWrapper';
import Page from 'components/Page';
import { RouterLink } from 'components/RouterLink';
import BaseTable from 'components/design-system/Table/BaseTable';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { SyntheticEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDebounce } from 'react-use';
import { Breadcrumbs, Button, InfoTooltip, RadioGroup } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Searchbar } from 'components/design-system/Searchbar';
import { useAddonIdToNameMap } from 'hooks/useAddonIdToNameMap';
import { useListAddonInstancesInfinite } from 'api/frontend-infinite';
import { flattenPages } from 'utils/arrays';
import { Stack } from 'components/utils';
import { useListClustersInfinite } from 'api/frontend-infinite';
import { clusterFlattenPages } from 'utils/arrays';

function AddonUpgradeTemplatesRequestTemplate() {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { account, currentOrganization } = useUserAccountState();
  const addonUpgradeTemplatesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/templates/addons',
  );
  const clustersBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  //Subscriptions
  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization?.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
    },
  );
  const showTemplateLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_template',
      )
    : true;

  // Clusters
  const { data: clustersList, isLoading: isLoadingClustersList } =
    useListClustersInfinite(
      '',
      { status: 'active' },
      {
        request: { headers: { Authorization: `Bearer ${account?.token}` } },
      },
      {
        initialSize: 100,
        revalidateFirstPage: false,
      },
    );

  const clusterData = clusterFlattenPages(clustersList || []) || [];

  const realClusters = clusterData
    ? clusterData.filter((c) => c.internal_k8s_ref !== 'example-cluster-ref')
    : [];

  const clusterIdToDetailsMap: Map<string, Cluster> = new Map(
    realClusters.map((c) => [c.id, c]),
  );

  //  Add-ons
  const addonIdToNameMap = useAddonIdToNameMap({ token: account.token });
  const [selectedAddonInstance, setSelectedAddonInstance] = useState<
    AddonInstance & {
      addon_name: string;
      cluster_name: string;
      environment: string;
    }
  >();

  const { data: addonInstancesData, isLoading: addonInstancesLoading } =
    useListAddonInstancesInfinite(
      {
        filter: ['status:active'],
      },
      {
        request: { headers: { Authorization: `Bearer ${account.token}` } },
      },
    );
  const addonInstances = flattenPages(addonInstancesData || []);

  const tableData = useMemo(() => {
    const addonInstancesWithDetails =
      addonInstances && addonIdToNameMap.size
        ? addonInstances.map((instance) => ({
            ...instance,
            addon_name: addonIdToNameMap.get(instance.addon_id) || '',
            cluster_name:
              clusterIdToDetailsMap.get(instance.cluster_id)?.name || '',
            environment:
              clusterIdToDetailsMap.get(instance.cluster_id)?.environment || '',
          }))
        : [];
    return addonInstancesWithDetails?.sort((a, b) => {
      return a.addon_name.localeCompare(b.addon_name);
    });
  }, [addonInstances, addonIdToNameMap, clustersList]);

  const [searchAddon, setSearchAddon] = useState('');
  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchAddon(newValue);
  };

  useDebounce(
    () => {
      if (searchAddon) {
        logEvent('search-addon', { searchAddon });
      }
    },
    1000,
    [searchAddon],
  );

  const addonsToShow = useMemo(() => {
    return searchAddon
      ? tableData?.filter((item) =>
          item?.addon_name.toLowerCase().includes(searchAddon.toLowerCase()),
        )
      : tableData;
  }, [addonInstancesData, clustersList, searchAddon, tableData.length]);

  const isTableLoading =
    addonInstancesLoading || isLoadingClustersList || !addonIdToNameMap?.size;
  const isClusterOboarded = realClusters?.length;

  const [requestTemplateLoading, setRequestTemplateLoading] = useState(false);
  const requestUpgradeTemplate = async function (type: DeploymentStrategy) {
    if (requestTemplateLoading) {
      return;
    }
    setRequestTemplateLoading(true);
    logEvent('upgrades-templates-strategy-selected', {
      upgrade_template_type: type,
    });
    if (!selectedAddonInstance?.id) {
      enqueueSnackbar(
        'Select an add-on instance to create an Upgrade Template',
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      setRequestTemplateLoading(false);
      return;
    }

    try {
      const createdUpgradeTemplate: UpgradeTemplate =
        await createUpgradeTemplate(
          {
            deployment_strategy: type || 'pick-for-me',
            environment: selectedAddonInstance.environment,
            resources: {
              addon_instances: [{ id: selectedAddonInstance.id }],
            },
          },
          { headers: { Authorization: `Bearer ${account.token}` } },
        );
      logEvent('addon-upgrade-template-created', {
        upgrade_template_type: type,
        clusterId: selectedAddonInstance.cluster_id,
        templateId: createdUpgradeTemplate?.id,
        upgradeTemplateName: createdUpgradeTemplate?.name,
      });
      enqueueSnackbar(
        "Upgrade Template request successful. We'll notify you once it is ready. Note that this process may take up to 10 days",
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      setRequestTemplateLoading(false);
      navigate(addonUpgradeTemplatesBasePath);
    } catch (err) {
      enqueueSnackbar(
        somethingWentWrong.replace(
          '<action>',
          'requesting this Upgrade Template',
        ),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      setRequestTemplateLoading(false);
    }
  };

  return (
    <Page title="Add-on Upgrade Templates">
      <div className="flex h-full flex-col items-start gap-6 bg-default-background px-3 pt-8 ml-8 w-[calc(100% - 32px]">
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item>Upgrade Templates</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <RouterLink to={addonUpgradeTemplatesBasePath}>
            <Breadcrumbs.Item>Add-ons</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Request Template</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex w-full items-center justify-end gap-2">
            <div className="flex grow shrink-0 basis-0 flex-col items-start justify-end gap-2">
              <span className="text-section-header font-section-header text-default-font">
                Request Add-on Upgrade Template
              </span>
              <span className="text-body font-body text-subtext-color">
                Select a representative Add-on Instance to get an Upgrade
                Template
              </span>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-subheader font-subheader text-default-font">
              Select a Representative Add-on Instance
            </span>
            <InfoTooltip
              className="h-auto w-3.5 flex-none"
              tooltipText="An Upgrade Template can be used to instantiate Upgrade Plans for matching add-on instances across fleet."
            />
          </div>
          {tableData?.length ? <Searchbar onChange={handleOnChange} /> : <></>}
        </div>
        {isTableLoading && <ConsoleLoader />}

        {isClusterOboarded ? (
          <>
            <BaseTable
              settings={{
                localStorageKey: 'create_upgrade_templates_table',
                dataCyPrefix: 'create-addon-upgrade-template',
              }}
              data={addonsToShow}
              noMatchingData={Boolean(tableData.length && !addonsToShow.length)}
              onRowClick={(addonInstance) => {
                setSelectedAddonInstance(addonInstance);
              }}
              columns={[
                {
                  id: 'addon',
                  title: 'Add-on',
                  titleStyle: { width: '40%' },
                  cellType: 'cell',
                  render: (addonInstance) => {
                    return (
                      <RadioGroup
                        className="max-w-[256px] whitespace-nowrap overflow-hidden overflow-ellipsis"
                        label=""
                        helpText=""
                        horizontal={true}
                        value=""
                      >
                        <RadioGroup.Option
                          checked={
                            selectedAddonInstance?.id === addonInstance.id
                          }
                          label={addonInstance.addon_name}
                          value={addonInstance.id}
                        />
                      </RadioGroup>
                    );
                  },
                },
                {
                  id: 'tag',
                  title: (
                    <Stack direction="row" spacing={1}>
                      Tag
                      <InfoTooltip tooltipText="The Tags that have been locally applied to an Add-on's container images" />
                    </Stack>
                  ),
                  titleStyle: { width: '30%' },
                  cellType: 'cell',
                  render: (addonInstance) => {
                    return (
                      <span className="whitespace-nowrap text-body font-body text-subtext-color">
                        {addonInstance.primary_component?.image?.tag}
                      </span>
                    );
                  },
                },
                {
                  id: 'cluster',
                  title: 'Cluster',
                  titleStyle: { width: '30%' },
                  cellType: 'cell',
                  render: (addonInstance) => {
                    return (
                      <span className="text-body font-body text-subtext-color">
                        {addonInstance.cluster_name}
                      </span>
                    );
                  },
                },
              ]}
              actions={[]}
            />
            <LockingWrapper
              feature={ResolvedEntitlementFeaturesItem.request_upgrade_template}
              showLock={showTemplateLock}
              defaultAction={() => requestUpgradeTemplate('pick-for-me')}
            >
              <Button
                size="large"
                loading={requestTemplateLoading}
                disabled={!selectedAddonInstance?.id || requestTemplateLoading}
              >
                Request Add-on Upgrade Template
              </Button>
            </LockingWrapper>
          </>
        ) : (
          <div className="flex items-center gap-2">
            <span className="text-body font-body text-default-font">
              No clusters onboarded so far. Click here to onboard your first
              cluster
            </span>
            <RouterLink to={`${clustersBasePath}/new`}>
              <Button
                size="small"
                icon="FeatherPlus"
                onClick={() =>
                  logEvent(
                    'upgrades-templates-create-onboard-cluster-btn-click',
                  )
                }
              >
                Add Cluster
              </Button>
            </RouterLink>
          </div>
        )}
      </div>
    </Page>
  );
}
export default AddonUpgradeTemplatesRequestTemplate;
